var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl-p-3",attrs:{"data-testid":"work-item-linked-items-list"}},[(_vm.heading)?_c('h3',{staticClass:"gl-mb-0 gl-mt-0 gl-block gl-rounded-base gl-bg-strong gl-px-3 gl-py-2 gl-text-sm gl-font-semibold gl-text-subtle",attrs:{"data-testid":"work-items-list-heading"}},[_vm._v("\n    "+_vm._s(_vm.heading)+"\n  ")]):_vm._e(),_vm._v(" "),_c(_vm.listRootComponent,_vm._b({ref:"list",tag:"component",staticClass:"work-items-list content-list",class:{
      'sortable-container gl-cursor-grab': _vm.canReorder,
      'disabled-content': _vm.updateInProgress,
    },attrs:{"move":_vm.handleMove},on:{"start":_vm.handleDragStart,"end":_vm.handleDragEnd}},'component',_vm.listOptions,false),_vm._l((_vm.linkedItems),function(linkedItem){return _c('li',{key:linkedItem.workItem.id,staticClass:"linked-item !gl-border-x-0 !gl-border-b-1 !gl-border-t-0 !gl-border-solid !gl-px-0 !gl-py-2 last:!gl-border-b-0",attrs:{"data-testid":"link-child-contents-container","data-work-item-id":linkedItem.workItem.id}},[_c('work-item-link-child-contents',{class:{
          'gl-border-default gl-bg-blue-50 hover:gl-bg-blue-50':
            _vm.activeChildItemId === linkedItem.workItem.id,
        },attrs:{"child-item":linkedItem.workItem,"can-update":_vm.canUpdate,"show-labels":_vm.showLabels,"work-item-full-path":_vm.workItemFullPath},on:{"click":function($event){return _vm.handleLinkedItemClick($event, linkedItem)},"removeChild":function($event){return _vm.$emit('removeLinkedItem', linkedItem.workItem)}}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }